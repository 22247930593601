// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'name',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'group_type',
    Object: 'value',
    label: '',
    width: '150'
  }, {
    prop: 'pricing_tier',
    label: '',
    Object: 'value',
    width: '120'
  }]
}
