import { updateOnlineCouponOnline, updateOnlineCouponOffline } from '@/services/coupon'
import { getUserGroupSetup, getUserGroups, userGroupDelete } from '@/services/members'
import jsonData from './jsonData.js'
import allButton from '@/components/allButton'
import searchForm from '@/components/searchForm'
import createTable from '@/components/createTable'
import dialogEffect from '@/components/dialogEffect'
import { mixin } from '@/mixins/mixin'
import loading from '@/utils/loading.json'
export default {
  name: 'OnlineCoupons',
  mixins: [mixin],
  components: {
    searchForm,
    createTable,
    allButton,
    dialogEffect
  },
  data () {
    return {
      tableDataItem: jsonData.tableDataItem
    }
  },
  watch: {
    $route (to, from) {
      // console.log(from)
      // if (from.name === 'managerUserOrderView') {
      //   this.$nextTick(() => {
      //     this.search()
      //     this.$refs.createTable.$refs.table.doLayout()
      //   })
      // }
    }
  },
  created () {
    this.queryData(getUserGroupSetup)
  },
  activated () {
    if (this.isActive) this.queryData(getUserGroupSetup)
    this.isActive = true
  },
  methods: {
    // 点击操作按钮
    async handleClick (type, data) {
      if (type === 'create') {
        this.$router.push({
          name: 'userGroupAdd'
        })
      }
      if (type?.action === 'view') {
        this.$router.push({
          name: 'couponManagerOnlineCouponDetail',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'create') {
        this.$router.push({
          name: 'userGroupAdd'
        })
      }
      if (type?.action === 'edit') {
        this.$router.push({
          name: 'userGroupEdit',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'destroy') {
        this.deleteClick(data, userGroupDelete)
      }
      if (type?.action === 'edit_shop_activity_items') {
        this.editUserGroupMember(data)
      }
    },
    editUserGroupMember (data) {
      this.$router.push({
        name: 'userGroupMembersAdd',
        query: {
          user_group_id: data.id.value,
          user_type: data.group_type.value.toLowerCase()
        }
      })
    },
    handleSwitchChange (data) {
      const { jsonItemData, rowData } = data
      this.$store.state.app.loading = this.$loading(loading)
      if (rowData[jsonItemData.prop].value) {
        updateOnlineCouponOnline(rowData.id.value).then(res => {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
        }).finally(() => {
          this.$store.state.app.loading.close()
        })
      } else {
        updateOnlineCouponOffline(rowData.id.value).then(res => {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
        }).finally(() => {
          this.$store.state.app.loading.close()
        })
      }
    },
    // 查询
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      getUserGroups(this.searchData).then(res => {
        if (res.data) {
          this.tableData = res.data.objects
          this.tabData = res.data.scopes
          Object.assign(this.pageData, res.data.pagination)
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
